jQuery(document).ready(function ($) {
  // primary nav
  $('.nav-primary').accessibleNav({
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });

  //Nav Functionality - Start
  $('.nav-primary .nav-toggle').click(function () {
    $('.nav-primary .ul1').toggle();
    $('.nav-toggle .fa-bars').toggle();
    $('.nav-toggle .fa-times').toggle();
    $('header').toggleClass('fixed');
    $('body').toggleClass('fixed');
  });

  $('.nav-primary .nav-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.nav-primary .ul1').toggle();
      $('.nav-toggle .fa-bars').toggle();
      $('.nav-toggle .fa-times').toggle();
      $('header').toggleClass('fixed');
      $('body').toggleClass('fixed');
    }
  });

  $('.nav-primary span.toggler').on('keypress', function (e) {
    if (e.which === 13) {
      $(this).toggleClass('open');
    }
  });

  $('.nav-primary a:last').on('focusout', function () {
    $('.nav-toggle').focus();
  });
  //Nav Functionality - END

  //Search Functionality - Start
  $('.search-toggle').click(function () {
    $('.search-box').toggleClass('active');
    $('.search-toggle .fa-search').toggleClass('active');
    $('.search-toggle .fa-times').toggleClass('active');
    $('.search-container input').focus();
    $('body').toggleClass('search-open');
    //If Alert is present, grab height of alert and offset active search box
    if ($(".alerts").is(":visible")) {
      console.log("Alerts is present.");
      $('.alerts').height(function (index, height) {
        $('.search-box').css("top", height + 22);
        console.log("Added" + " " + height + "px margin-top to nav");
      });
      //If alerts are closed, then remove style attribute
      $('.alerts .close').click(function () {
        $('.search-box').removeAttr('style');
        console.log("Removed added margin-top");
      });
      $('.alerts .close').on('keypress', function (e) {
        if (e.which === 13) {
          $('.search-box').removeAttr('style');
          console.log("Removed added margin-top");
        }
      });
    } else {
      console.log("Alerts is NOT present.");
    }
  });

  $('.search-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.search-box').toggleClass('active');
      $('.search-toggle .fa-search').toggleClass('active');
      $('.search-toggle .fa-times').toggleClass('active');
      $('.search-container input').focus();
      $('body').toggleClass('search-open');
      //If Alert is present, grab height of alert and offset active search box
      if ($(".alerts").is(":visible")) {
        console.log("Alerts is present.");
        $('.alerts').height(function (index, height) {
          $('.search-box').css("top", height + 22);
          console.log("Added" + " " + height + "px margin-top to nav");
        });
        //If alerts are closed, then remove style attribute
        $('.alerts .close').click(function () {
          $('.search-box').removeAttr('style');
          console.log("Removed added margin-top");
        });
        $('.alerts .close').on('keypress', function (e) {
          if (e.which === 13) {
            $('.search-box').removeAttr('style');
            console.log("Removed added margin-top");
          }
        });
      } else {
        console.log("Alerts is NOT present.");
      }
    }
  });

  $('.nav-toggle').click(function () {

  });

  //Nav Functionality - END

  //Scroll to Top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $(".scroll-to-top").css("opacity", "1");
    } else {
      $(".scroll-to-top").css("opacity", "0");
    }
  });
  $(".scroll-to-top").click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
  $(".scroll-to-top").on('keypress', function (e) {
    if (e.which === 13) {
      $('html, body').animate({
        scrollTop: 0
      }, 1000);
      return false;
    }
  });

  new Splide('.image-banner', {
    perPage: 1,
    type: 'loop',
    cover: true,
    arrows: true,
    pagination: true,
    autoHeight: true,
    heightRatio: true,
    gap: 0,
    autoplay: true,
    // breakpoints: {
    //   768: {
    //     perPage: 1,
    //     pagination: true
    //   },
    //   1024: {
    //     perPage: 2,
    //     pagination: true
    //   },
    // },
    classes: {
      pagination: 'splide__pagination dots', // container
    },
  }).mount();

});